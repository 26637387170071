h1,
h2,
h3,
h4,
p,
fieldset,
ul,
ol {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

body {
  font-family: 'DINNextW01', Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #21262d;
}


@media screen and (max-width: 922px) {
  .BeaconFabButtonFrame {
    bottom: 75px !important;
  }
}

fieldset,
button,
input,
input:focus,
textarea {
  border: none;
  outline: none;
}

button {
  padding: 0;
}

input,
textarea {
  border-radius: 0;
  -webkit-appearance: none;
}

a {
  color: inherit;
  text-decoration: none;
}

textarea {
  padding: 0;
}

ul {
  list-style: none;
}

input {
  caret-color: #16c0e8;
}

input::placeholder,
textarea::placeholder {
  color: #dadee3;
  opacity: 1; /* Firefox */
}

input:-ms-input-placeholder,
textarea:-ms-textarea-placeholder {
  /* Internet Explorer 10-11 */
  color: #dadee3;
}

input::-ms-input-placeholder,
textarea::-ms-textarea-placeholder {
  /* Microsoft Edge */
  color: #dadee3;
}

button {
  outline: none;
  border: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-text-fill-color: #21262d;
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}

html {
  background: #f0f2f5;
}
